<script>
    // -- IMPORTS

    import { enabledAdminView } from '$lib/store/adminStore.js';
    import AdminEditButton from '../element/AdminEditButton.svelte';
    import { onMount } from 'svelte';
    import Marker from './Marker.svelte';
    import { getImagePath } from '$src/lib/filePath';

    // -- VARIABLES
    
    export let networkingArray = [];
    let imageWidth = 1430;
    let imageHeight = 612;
    let imageElement;

    // -- FUNCTIONS

    function updateHubArray(
        )
    {
        networkingArray = networkingArray.map(
            city =>
            {
                let x, y;
                
                if ( city.coordinateMap )
                {
                    let responsiveCoordinateRefinement = city.coordinateMap;
                    x = Number( responsiveCoordinateRefinement.x || 0 );
                    y = Number( responsiveCoordinateRefinement.y || 0 );
                }

                return (
                    {
                        ...city,
                        x,
                        y
                    }
                    );
            }
            );
    }

    // ~~

    function handleResizeEvent(
        )
    {
        imageWidth = imageElement?.offsetWidth;
        imageHeight = imageElement?.offsetHeight;

        updateHubArray();
    }

    // -- STATEMENTS

    onMount(
        () =>
        {		
            window.addEventListener( 'resize', handleResizeEvent );
            
            return () =>
                {
                    window.removeEventListener( 'resize', handleResizeEvent );
                }
        }
        );

    // ~~

    onMount( updateHubArray );

    // ~~

    $: updateHubArray();
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .map-container
    {
        +media( desktop )
        {
            max-width: 77vw;
        }
    }

    .map-content
    {
        position: relative;
    }

    .map-image
    {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
</style>

<div class="map-container" class:is-admin-enabled={ $enabledAdminView }>
    <AdminEditButton
        type="network-city"
    />

    <div class="map-content">
        <img bind:this={ imageElement } class="map-image" src="/image/networking/map.svg" alt="" on:load={ handleResizeEvent }/>
        {#each networkingArray as hub, hubIndex }
            <Marker marker={ hub } markerIndex={ hubIndex }/>
        {/each}
    </div>
</div>